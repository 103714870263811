import axios from "axios";
import authHeader from "./authHeader";

const API = process.env.VUE_APP_API_URL + "/election_methods";

export default {
  get() {
    return axios.get(API, {
      headers: authHeader()
    });
  }
};
