import axios from "axios";
import authHeader from "./authHeader";

const API = process.env.VUE_APP_API_URL + "/answers";

export default {
  index(questionId) {
    return axios.get(process.env.VUE_APP_API_URL + "/questions/" + questionId + '/answers', {
      headers: authHeader()
    });
  },
  create(questionId) {
    return axios.post(process.env.VUE_APP_API_URL + "/questions/" + questionId + '/answers', {}, {
      headers: authHeader()
    });
  },
  update(answer) {
    return axios.put(API + '/' + answer.id, { "answer": answer }, {
      headers: authHeader()
    });
  },
  destroy(answer) {
    return axios.delete(API + '/' + answer.id, {
      headers: authHeader()
    });
  }
};