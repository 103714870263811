<template>
  <div class="answer mb-5 p-5">
    <v-text-field
      v-model="answer.name"
      :label="label"
      :error-messages="errorFor('name')"
      @blur="submit"
      :disabled="disabled"
    ></v-text-field>
    <wswgComponent
      :parentData="answer.additional_information"
      placeholder="Información adicional"
      @wswgSubmit="wswgSubmit"
      :disabled="disabled"
    />
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        @click.prevent="removeAnswer"
        class="no-underline white--text mx-auto my-2"
        color="red"
        rounded
        :disabled="disabled"
      >
        <v-icon left>mdi-minus-circle</v-icon>
        Quitar {{ answer.name }}
      </v-btn>
    </v-row>
    <hr class="my-5" />
  </div>
</template>

<script>
import answers from "@/services/answers";
import wswgComponent from "@/components/wswg/wswg.vue";
import errors from "@/mixins/errors";

export default {
  name: "AnswerForm",
  mixins: [errors],
  components: {
    wswgComponent
  },
  props: ["answer", "label", "disabled"],
  methods: {
    removeAnswer() {
      let self = this;
      answers.destroy(self.answer).then(() => {
        // destroy the vue listeners, etc
        self.$destroy();

        // remove the element from the DOM
        self.$el.parentNode.removeChild(self.$el);
      });
    },
    submit() {
      let self = this;
      answers
        .update(this.answer)
        .then(() => {
          self.errors = {};
        })
        .catch(error => {
          if (error.response) {
            self.errors = error.response.data;
          }
        });
    },
    wswgSubmit(data) {
      this.answer.additional_information = data;
      this.submit();
    }
  }
};
</script>
