<template>
  <v-card flat>
    <v-card-text>
      <questionForm
        v-for="question in questions"
        :key="question.id"
        :pQuestion="question"
        :pElectionMethods="electionMethods"
        :disabled="!canEdit"
      ></questionForm>
      <v-btn
        @click.prevent="addQuestion"
        class="no-underline mt-5"
        color="yellow darken-3"
        block
        rounded
        :disabled="!canEdit"
      >
        <v-icon left>mdi-plus-circle</v-icon>
        Agregar consulta
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('edit')"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>Atrás
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="red"
        class="mx-auto mt-5"
        width="40%"
        rounded
        dark
        :to="getRoute('features')"
      >
        Siguiente<v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import questions from "@/services/questions";
import electionMethods from "@/services/election_methods";
import questionForm from "@/components/questions/Form";
import electionCanEdit from "@/mixins/electionCanEdit";

export default {
  name: "BallotQuestions",
  mixins: [electionCanEdit],
  props: ["election"],
  data: () => ({
    questions: [],
    electionMethods: []
  }),
  components: {
    questionForm
  },
  watch: {
    election() {
      this.loadQuestions();
    }
  },
  mounted() {
    electionMethods.get().then(response => {
      this.electionMethods = response.data;
    });
  },
  methods: {
    loadQuestions() {
      questions.index(this.election.id).then(response => {
        this.questions = response.data;
      });
    },
    addQuestion() {
      let self = this;
      questions.create(this.election.id).then(response => {
        self.questions.push(response.data);
      });
    },
    getRoute(option) {
      return "/wizard/" + this.election.id + "/" + option;
    }
  }
};
</script>