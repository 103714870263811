<template>
  <div class="quesetions-election">
    <wizard />
    <p class="text-center">
      <v-btn class="blue"
             dark
             @click="preview">Vista previa</v-btn>
    </p>
    <h2>{{ election.name }}</h2>
    <componentQuestions :election="election" />
  </div>
</template>

<script>
import loadElection from "@/mixins/loadElection";

import wizard from "@/components/wizard/wizard.vue";
import componentQuestions from "@/components/ballots/BallotQuestions.vue";

export default {
  mixins: [loadElection],
  components: {
    wizard,
    componentQuestions
  },
  methods: {
    preview() {
      this.$router.push("/preview/"
        + this.$route.params.id
        + "&" + "wizard-questions");
    }

  }
};
</script>