<template>
  <v-card class="question pa-0 mb-5" outlined>
    <v-card-title class="red">
      <v-btn text
             dark>{{ question.name }}</v-btn>
      <v-btn absolute
             right
             dark
             icon
             class="mr-10"
             @click="minimizeQuestion">
        <v-icon>{{ expandIcon }}</v-icon>
      </v-btn>
      <v-btn absolute
             right
             icon
             dark
             @click.prevent="removeQuestion()"
             :disabled="disabled">
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pt-5" v-show="expand">
      <v-select
        :items="electionMethods"
        item-text="name"
        item-value="id"
        label="Tipo de consulta"
        v-model="question.election_method_id"
        @change="submit"
        :error-messages="errorFor('election_method_id')"
        :disabled="disabled"
      ></v-select>

      <v-text-field
        v-model="question.name"
        label="Consulta"
        :error-messages="errorFor('name')"
        @blur="submit"
        :disabled="disabled"
      ></v-text-field>

      <fieldset v-if="answers.length > 0" class="px-5">
        <legend>{{ question.translations.other }}</legend>
        <answerForm
          v-for="answer in answers"
          :key="answer.id"
          :answer="answer"
          :label="question.answer_label"
          :disabled="disabled"
        ></answerForm>
        <v-card-actions> </v-card-actions>
      </fieldset>

      <v-btn
        @click.prevent="addAnswer"
        class="no-underline white--text mx-auto my-2"
        color="red"
        rounded
        :disabled="disabled"
      >
        <v-icon left>mdi-plus-circle</v-icon>
        Agregar {{ question.translations.one }}
      </v-btn>

      <v-row>
        <v-col>
          <v-checkbox
            v-model="question.allow_random_order"
            label="Orden aleatorio"
            @click="submit"
            :disabled="disabled"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-model="question.allow_abstain"
            label="Permitir abtenerse"
            @click="submit"
            :disabled="disabled"
          ></v-checkbox>
        </v-col>
      </v-row>

      <wswgComponent
        :parentData="question.instructions"
        placeholder="Instrucciones para el votante"
        @wswgSubmit="wswgSubmit"
        :disabled="disabled"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import errors from "@/mixins/errors";

import questions from "@/services/questions";
import answers from "@/services/answers";

import answerForm from "@/components/answers/Form";
import wswgComponent from "@/components/wswg/wswg.vue";

export default {
  name: "QuestionForm",
  mixins: [errors],
  components: {
    answerForm,
    wswgComponent
  },
  props: ["pQuestion", "disabled", "pElectionMethods"],
  data: () => ({
    answers: [],
    electionMethods: [],
    question: {
      translations: {
        one: null
      }
    },
    expand: true,
    expandIcon: "mdi-window-minimize",

  }),
  mounted() {
    this.question = this.pQuestion;
    this.electionMethods = this.pElectionMethods;
    answers.index(this.pQuestion.id).then(response => {
      this.answers = response.data;
    });
  },
  computed: {},
  methods: {
    removeQuestion() {
      console.log('eliminado');
      let self = this;
      questions.destroy(self.question).then(() => {
        // destroy the vue listeners, etc
        self.$destroy();

        // remove the element from the DOM
        self.$el.parentNode.removeChild(self.$el);
      });
    },
    submit() {
      let self = this;
      questions
        .update(this.question)
        .then(response => {
          self.errors = {};
          this.question = response.data;
        })
        .catch(error => {
          if (error.response) {
            self.errors = error.response.data;
          }
        });
    },
    addAnswer() {
      let self = this;
      answers.create(this.question.id).then(response => {
        self.answers.push(response.data);
      });
    },
    wswgSubmit(data) {
      this.question.instructions = data;
      this.submit();
    },
    minimizeQuestion() {
      this.expand = !this.expand;
      this.expandIcon = (this.expand)
        ? "mdi-window-minimize"
        : "mdi-window-maximize";
    }
  }
};
</script>
