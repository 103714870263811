import axios from "axios";
import authHeader from "./authHeader";

const API = process.env.VUE_APP_API_URL + "/questions";

export default {
  index(electionId) {
    return axios.get(process.env.VUE_APP_API_URL + "/elections/" + electionId + '/questions', {
      headers: authHeader()
    });
  },
  create(electionId) {
    return axios.post(process.env.VUE_APP_API_URL + "/elections/" + electionId + '/questions', {}, {
      headers: authHeader()
    });
  },
  update(question) {
    return axios.put(API + '/' + question.id, { "question": question }, {
      headers: authHeader()
    });
  },
  destroy(question) {
    return axios.delete(API + '/' + question.id, {
      headers: authHeader()
    });
  }
};